/** @jsx jsx */
import {jsx, useColorMode, Styled} from "theme-ui"
import {Flex} from "@theme-ui/components"
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata";
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config";
import {Link} from "gatsby";
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes";
import ColorModeToggle from "@lekoarts/gatsby-theme-minimal-blog/src/components/colormode-toggle";
import Navigation from "@lekoarts/gatsby-theme-minimal-blog/src/components/navigation";
// import Logo from "./logo"


const Header = () => {
  const {siteTitle} = useSiteMetadata()
  const {navigation: nav, externalLinks, basePath} = useMinimalBlogConfig()
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = (e) => {
    e.preventDefault()
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <header sx={{mb: [5, 6]}}>
      <Flex sx={{alignItems: `center`, justifyContent: `space-between`}}>
        <Link
          to={replaceSlashes(`/${basePath}`)}
          aria-label={`${siteTitle} - Back to home`}
          sx={{color: `heading`, textDecoration: `none`}}
        >
          <h1 sx={{my: 0, fontWeight: `medium`, fontSize: [3, 4]}}>{siteTitle}</h1>
        </Link>
        <ColorModeToggle isDark={isDark} toggle={toggleColorMode}/>
      </Flex>
      <div
        sx={{
          boxSizing: `border-box`,
          display: `flex`,
          variant: `dividers.bottom`,
          alignItems: `center`,
          justifyContent: `space-between`,
          mt: 3,
          color: `secondary`,
          a: {color: `secondary`, ":hover": {color: `heading`}},
          flexFlow: `wrap`,
        }}
      >
        <Navigation nav={nav}/>
        {externalLinks && externalLinks.length > 0 && (
          <div sx={{"a:not(:first-of-type)": {ml: 3}, fontSize: [1, `18px`]}}>
            {externalLinks.map(link => (
              <Styled.a key={link.url} href={link.url}>
                {link.name}
              </Styled.a>
            ))}
          </div>
        )}
      </div>
      {/*<Logo/>/*/}
    </header>
  )
}

export default Header
